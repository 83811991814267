<script lang="ts">
  // Stores
  import { getCarouselContext } from "$lib/utils/section-carousel"

  export let id: string | null = null;

  const { registerSlides } = getCarouselContext()

  let slidesContainer: HTMLElement

  $: {
    if (slidesContainer) {
      const slides = slidesContainer.children
      registerSlides(id, Array.from(slides) as HTMLElement[])
    }
  }
</script>

<div class="section-carousel-slides" bind:this="{slidesContainer}" data-forward>
  <slot />
</div>

<style type="text/scss" lang="scss" scoped>
  .section-carousel-slides {
    @apply contents;
  }
</style>
