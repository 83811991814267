import { setContext, getContext } from "svelte";
import { fade } from "svelte/transition";
import { expoInOut } from "svelte/easing";
import { writable, get } from "svelte/store";
import { onDestroy } from "svelte";
import type { CarouselConfig, CarouselState, CarouselContext, Carousels } from "./types";

const carousels = writable<Carousels>({});

const slidesPerPage = (slides: number, pages: number) => Math.ceil(slides / pages);

const getPagesCount = (config: CarouselConfig, slidesCount: number) => {
  const { mobileCount, desktopCount, desktopBreakpoint } = config;
  const isMobile = (document?.documentElement.clientWidth ?? 0) < desktopBreakpoint;
  const disableOnMobile = isMobile && mobileCount === 0;
  return disableOnMobile ? 1 : Math.ceil(slidesCount / (isMobile ? mobileCount : desktopCount));
};

const updateVisibleSlides = (id: string) => {
  if (typeof window === "undefined") return;

  const { slides, pagesCount, currentIndex } = get(carousels)[id];
  const slideList = <any[]>get(slides);

  if (!slides || !pagesCount || !slideList.length) return;

  const perPage = slidesPerPage(slideList.length, get(pagesCount));
  const start = <number>get(currentIndex) * perPage;
  const end = start + perPage - 1;
  const parent = slideList.find(slide => !!slide.parentElement)?.parentElement;

  slideList.forEach((slide, i) => {
    const isInPage = i >= start && i <= end;
    if (isInPage) {
      parent?.appendChild(slide);
    } else {
      slide.remove();
    }
  });
};

const animate = (el: HTMLElement, type: "in" | "out", direction: "left" | "right") => {
  if (type === "in") {
    fade(el, { duration: 300, easing: expoInOut });
  } else {
    // Add custom logic or transition for "out" animations if needed.
  }
};

const changeSlides = (carousel: CarouselState, prevIndex: number, newIndex: number) => {
  const { slides, pagesCount } = carousel;
  const slideList = <any[]>get(slides);
  if (prevIndex === -1 || newIndex >= slideList.length) return;

  const perPage = slidesPerPage(slideList.length, get(pagesCount));
  const start = prevIndex * perPage;
  const end = start + perPage;
  const currentSlides = slideList.slice(start, end);

  if (currentSlides.length) {
    const start = newIndex * perPage;
    const end = start + perPage;
    const nextSlides = slideList.slice(start, end);
    const parent = currentSlides[0].parentElement;

    currentSlides.forEach(slide => slide.remove());
    nextSlides.forEach(slide => parent.appendChild(slide));
    nextSlides.forEach(slide => animate(slide, "in", newIndex > prevIndex ? "left" : "right"));
  }
};

const setupContext = () =>
  setContext<CarouselContext>("SECTION-CAROUSEL", {
    carousels,
    registerCarousel(id, config) {
      const carouselList = get(carousels);

      if (!(id in carouselList)) {
        carouselList[id] = {
          mobileCount: config.mobileCount,
          desktopCount: config.desktopCount,
          desktopBreakpoint: config.desktopBreakpoint,
          pagesCount: writable(0),
          currentIndex: writable(0),
          slides: writable<HTMLElement[]>([]),
          duration: config.duration,
        };
        carousels.set(carouselList);
      }
    },
    registerSlides(id, slides) {
      carousels.update(carouselList => {
        const newCarouselList = { ...carouselList };
        if (newCarouselList[id]) {
          newCarouselList[id].slides.set(slides);

          const pagesCount = getPagesCount(newCarouselList[id], slides.length);
          newCarouselList[id].pagesCount.set(pagesCount);

          updateVisibleSlides(id);
        }
        return newCarouselList;
      });

      onDestroy(() => {
        carousels.update(carouselList => {
          const newCarouselList = { ...carouselList };
          const parent = get(newCarouselList[id].slides).find(slide => !!slide.parentElement)?.parentElement;
          newCarouselList[id].slides.update(slides => {
            slides.forEach(slide => {
              if (!slide.parentNode && parent) {
                parent.appendChild(slide);
              }
            });
            return [];
          });
          delete newCarouselList[id];
          return newCarouselList;
        });
      });
    },
    refreshPages() {
      const carouselList = get(carousels);
      for (const key in carouselList) {
        const carousel = carouselList[key];
        const pagesCount = getPagesCount(carousel, get(carousel.slides).length);
        if (pagesCount !== get(carousel.pagesCount)) {
          const currentIndex = <number>get(carousel.currentIndex);
          if (currentIndex >= pagesCount) {
            carousel.currentIndex.set(pagesCount - 1);
            changeSlides(carousel, currentIndex, pagesCount - 1);
          }

          carousel.pagesCount.set(pagesCount);
          updateVisibleSlides(key);
        }
      }
    },
    updateCurrentIndex(id, index) {
      const carouselList = get(carousels);
      carouselList[id].currentIndex.update(prevIndex => {
        if (prevIndex !== index) {
          changeSlides(carouselList[id], prevIndex, index);
          return index;
        }
      });
    },
  });

const getCarouselContext = () => getContext<CarouselContext>("SECTION-CAROUSEL");

export { setupContext, getCarouselContext };